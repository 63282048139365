import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

import { dataLength } from '@/share';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/intro/0'
  },
  {
    path: '/intro',
    redirect: '/intro/0'
  },
  {
    path: '/intro/:pageID',
    name: 'Intro',
    component: () => import(/* webpackChunkName: "intro" */ '../views/IntroView.vue'),
    beforeEnter: (to) => {
      const pageNum = Number(to.params.pageID);
      return (isNaN(pageNum) || pageNum > 6 || pageNum < 0)
        ? router.push('/intro/0')
        : true;
    }
  },
  {
    path: '/intro/:catchAll(.*)',
    redirect: '/404notfound'
  },
  {
    path: '/portfolio',
    redirect: '/portfolio/0'
  },
  {
    path: '/portfolio/:pageID',
    name: 'Portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/PortfolioView.vue'),
    beforeEnter: (to) => {
      const pageNum = Number(to.params.pageID);
      return (isNaN(pageNum) || pageNum > dataLength.portfolios || pageNum < 0)
        ? router.push('/portfolio/0')
        : true;
    }
  },
  {
    path: '/piece',
    redirect: '/piece/2024_01'
  },
  {
    path: '/piece/:pageID',
    name: 'Piece',
    component: () => import(/* webpackChunkName: "store" */ '../views/ProductView.vue'),
    beforeEnter: (to) => {
      const pageNum = Number(String(to.params.pageID).split('_')[1]);
      return (isNaN(pageNum) || pageNum > dataLength.pieces || pageNum <= 0)
        ? router.push('/piece/2024_01')
        : true;
    }
  },
  {
    path: '/product',
    redirect: '/product/2024_01'
  },
  {
    path: '/product/:pageID',
    name: 'Product',
    component: () => import(/* webpackChunkName: "store" */ '../views/ProductView.vue'),
    beforeEnter: (to) => {
      const pageNum = Number(String(to.params.pageID).split('_')[1]);
      return (isNaN(pageNum) || pageNum > dataLength.products || pageNum <= 0)
        ? router.push('/product/2024_01')
        : true;
    }
  },
  {
    path: '/dev_portfolio',
    name: 'Dev Portfolio',
    component: () => import(/* webpackChunkName: "dev_portfolio" */ '../views/DevPortfolioView.vue')
  },
  {
    path: '/404notfound',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFoundView.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404notfound'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
