import { ref } from 'vue';
import { Router } from 'vue-router';

import portfolios from '@/assets/portfolios.json';
import pieces from '@/assets/pieces.json';
import products from '@/assets/products.json';

export const dataLength = {
  portfolios: portfolios.length,
  pieces: pieces.length,
  products: products.length
};

export interface TargetType {
  _id: string;
  img: {
    path: string;
    width: number;
    height: number;
    desktop: {
      mainWidth: string;
      mainHeight: string;
      miniWidth: string;
      miniHeight: string;
    };
    mobile: {
      mainWidth: string;
      mainHeight: string;
      miniWidth: string;
      miniHeight: string;
    };
  }[];
  price?: number;
  size?: string;
  material?: string[];
  light?: string;
  note?: string;
  desktop?: {
    translateX: string;
    translateY: string;
    translateMainX: string;
    translateMainY: string;
    translateMiniX: string;
    translateMiniY: string;
  };
  mobile?: {
    translateX: string;
    translateY: string;
    translateMainX: string;
    translateMainY: string;
    translateMiniX: string;
    translateMiniY: string;
  }
};

const switcherIdx = 1;

let timeoutID: ReturnType<typeof setTimeout> | null = null;

export const debounce = (
  delay: number,
  fn: (ev: KeyboardEvent | WheelEvent | MouseEvent | Router, pageArray:(string | number)[], router: Router) => void,
  ev: KeyboardEvent | WheelEvent | MouseEvent | Router, pageArray:(string | number)[],
  router:Router
):void => {
  if (timeoutID) {
    clearTimeout(timeoutID);
  };

  timeoutID = setTimeout(() => {
    fn(ev, pageArray, router);
  }, delay);
};

export const pageNext = (pageArray: (string | number)[], router: Router) => {
  const unitId = String(router.currentRoute.value.name).toLowerCase();
  const pageId = String(router.currentRoute.value.params.pageID);
  const pageIdx = pageArray.findIndex(el => String(el) === String(pageId)) || 0;

  pageIdx < (pageArray.length - 1) && router.push(`/${unitId}/${pageArray[pageIdx + 1]}`);
};

export const pagePrev = (pageArray: (string | number)[], router: Router) => {
  const unitId = String(router.currentRoute.value.name).toLowerCase();
  const pageId = String(router.currentRoute.value.params.pageID);
  const pageIdx = pageArray.findIndex(el => String(el) === String(pageId)) || 0;

  if (pageIdx > 1) {
    router.push(`/${unitId}/${pageArray[pageIdx - 1]}`);
  } else {
    router.push('/' + pageArray[0]);
  }
};

export const wheeler = (ev: KeyboardEvent | MouseEvent | WheelEvent | Router, pageArray:(string | number)[], router: Router):void => {
  if (!(ev instanceof WheelEvent) || window.innerWidth < 1024) return;
  const delY = ev.deltaY;
  if (delY > switcherIdx) pageNext(pageArray, router);
  else if (delY < switcherIdx * (-1)) pagePrev(pageArray, router);
};

export const posTouchStart = ref({ x: 0, y: 0 });

const posTouchEnd = ref({ x: 0, y: 0 });

export const touchEnd = (ev: TouchEvent, pageArray:(string | number)[], router: Router):void => {
  posTouchEnd.value = {
    x: ev.changedTouches[0].clientX,
    y: ev.changedTouches[0].clientY
  };

  const delPos = {
    x: posTouchEnd.value.x - posTouchStart.value.x,
    y: posTouchEnd.value.y - posTouchStart.value.y
  };

  if (delPos.y > 30 || delPos.y < -30) return;
  if (delPos.x < -30) pageNext(pageArray, router);
  if (delPos.x > 30) pagePrev(pageArray, router);
};

export const keyupFn = (ev: KeyboardEvent, pageArray:(string | number)[], router: Router):void => {
  const keyer = (ev: KeyboardEvent | WheelEvent | MouseEvent | Router) => {
    if (!(ev instanceof KeyboardEvent)) return;
    switch (ev.key) {
      case 'ArrowUp':
        pagePrev(pageArray, router);
        break;
      case 'ArrowDown':
        pageNext(pageArray, router);
        break;
      case 'ArrowLeft':
        pagePrev(pageArray, router);
        break;
      case 'ArrowRight':
        pageNext(pageArray, router);
        break;
    };
  };

  debounce(100, keyer, ev, pageArray, router);
};
