<template>
  <router-view
    :key="$route.fullPath"
  />
</template>

<style lang="scss">
img {
  -webkit-user-drag: none;
  user-select: none;
}
</style>
